<template>
  <EuiDialog size="medium" :open="open" @onClose="$emit('update:open', false)" class="confirm-dialog">
    <template #header>{{ $t('paraphers.dialogs.confirm.title') }}</template>
    <p class="eui-u-text-orange eui-u-font-bold">
      <EuiIcon class="eui-u-mr-2" name="error"/>
      {{ $t('paraphers.dialogs.confirm.warning') }}
    </p>
    <p class="eui-u-font-bold eui-u-text-base eui-u-text-blue-grey">
      {{ documentWarningMessage }}
    </p>
    <EuiExpansionPanel :opened="true" v-if="documents.length > 0">
      <template #header>{{ $t('paraphers.dialogs.confirm.documents') }}</template>
      <EuiList>
        <EuiListItem class="eui-u-pl-0" v-for="(document) in documents" :key="document.id">
          <template #graphic>
            <EuiIcon class="eui-u-text-blue-grey" name="file"/>
          </template>
          {{ document.name }}
        </EuiListItem>
      </EuiList>
    </EuiExpansionPanel>
    <p class="eui-u-font-bold eui-u-text-base eui-u-text-blue-grey">
      {{ $tc('paraphers.dialogs.confirm.nbSignatoriesNoZone', signatories.length) }}
    </p>
    <EuiExpansionPanel :opened="true" v-if="signatories.length > 0">
      <template #header>{{ $t('paraphers.dialogs.confirm.signatories') }}</template>
      <EuiList>
        <EuiListItem class="eui-u-pl-0" v-for="(signatory) in signatories" :key="signatory.userId">
          <template #graphic>
            <EuiIcon class="eui-u-text-blue-grey" name="person"/>
          </template>
          {{ signatory.fullname }}
        </EuiListItem>
      </EuiList>
    </EuiExpansionPanel>
    <template #footer>
      <EuiButton variant="text" color="primary" @click="$emit('update:open', false)">
        {{ $t('button.back')}}
      </EuiButton>
      <EuiButton variant="raised" color="primary" @click="$emit('confirm')">
        {{ $t('button.confirm')}}
      </EuiButton>
    </template>
  </EuiDialog>
</template>

<script>

export default {
  name: 'ConfirmDialog',
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    documents: {
      type: Array,
      default: () => [],
    },
    signatories: {
      type: Array,
      default: () => [],
    },
    externalUserHasZone: {
      type: Boolean,
      default: () => false,
    },
    hasExternalSignatory: {
      type: Boolean,
      default: () => false,
    },
    allSignatoriesAreExternal: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    signersOnlyMode() {
      const esProfile = JSON.parse(localStorage.getItem('es-profile'));
      const signerOnlyOption = esProfile.options.signerCanOnlySeeDocumentsToSign;
      return signerOnlyOption && signerOnlyOption.value ? signerOnlyOption.value === '1' : false;
    },
    documentWarningMessage() {
      const externalMessage = this.$i18n.tc('paraphers.dialogs.confirm.nbDocumentsNoZoneNoDisplay', this.documents.length);
      const internalMessage = this.$i18n.tc('paraphers.dialogs.confirm.nbDocumentsNoZone', this.documents.length);

      if (this.signersOnlyMode && this.allSignatoriesAreExternal) return externalMessage;
      if (!this.signersOnlyMode || !this.hasExternalSignatory) return internalMessage;
      if (this.hasExternalSignatory && !this.externalUserHasZone) return externalMessage;
      return internalMessage;
    },
  },
};
</script>
