<template>
  <ValidationObserver slim ref="observer" v-slot="{ handleSubmit, valid }" class="eds-paraphers-create">
    <ConfirmDialog
      :open.sync="isConfirmDialogOpened"
      :documents="noZoneDocuments"
      :signatories="noZoneSignatories"
      :allSignatoriesAreExternal="allSignatoriesAreExternal"
      :externalUserHasZone="externalUserHasZone"
      :hasExternalSignatory="hasExternalSignatory"
      @confirm="onConfirm"
    />
    <form ref="form" @submit.prevent="handleSubmit(onSubmit)" @reset="resetForm">
      <EuiContainer style="height: calc(100vh - 180px);">
        <EuiGrid gutters>
          <EuiGridRow>
            <EuiGridColumn>
              <EuiHeading :level="2" bold class="title" :class="{ '-enlarge': (isEnlarge && currentStep === 4) }">
                <template v-if="isTemplate">
                  {{ $t('paraphers.templates.title', { name: templateName }) }}
                </template>
                <template v-else>
                  {{ $t('paraphers.create.title') }}
                </template>
              </EuiHeading>
            </EuiGridColumn>
          </EuiGridRow>
          <EuiGridRow class="stepper" :class="{ '-enlarge': (isEnlarge && currentStep === 4) }">
            <EuiGridColumn>
              <EuiCard class="eui-u-py-4">
                <EuiStepper horizontal v-if="steps && currentStep && steps[currentStep - 1]">
                  <EuiStepperItem :class="{ 'eui-u-cursor-pointer': currentStep > 1 && currentStep !== 5 }"
                                  @click.native="goToStep(1)"
                                  :state="currentStep < 2 ? steps[currentStep - 1].isValid === false ? 'error' : 'inProgress' : 'valid'"
                                  :title="$t('paraphers.create.breadcrumbs.step1')"/>
                  <EuiStepperItem :class="{ 'eui-u-cursor-pointer': currentStep > 2 && currentStep !== 5 }"
                                  @click.native="goToStep(2)"
                                  :state="currentStep < 2 ? 'disabled' : currentStep < 3 ? steps[currentStep - 1].isValid === false ? 'error' : 'inProgress' : 'valid'"
                                  :title="$t('paraphers.create.breadcrumbs.step2')"/>
                  <EuiStepperItem :class="{ 'eui-u-cursor-pointer': currentStep > 3 && currentStep !== 5 }"
                                  @click.native="goToStep(3)"
                                  :state="currentStep < 3 ? 'disabled' : currentStep < 4 ? steps[currentStep - 1].isValid === false ? 'error' : 'inProgress' : 'valid'"
                                  :title="$t('paraphers.create.breadcrumbs.step3')"/>
                  <EuiStepperItem @click.native="goToStep(4)"
                                  :state="currentStep < 4 ? 'disabled' : currentStep < 5 ? steps[currentStep - 1].isValid === false ? 'error' : 'inProgress' : 'valid'"
                                  :title="$t('paraphers.create.breadcrumbs.step4')"/>
                </EuiStepper>
              </EuiCard>
            </EuiGridColumn>
          </EuiGridRow>
          <router-view @enlargePdf="isEnlarge = !isEnlarge"/>
        </EuiGrid>
      </EuiContainer>
      <EuiBottomBar v-if="currentStep !== 5" :class="bottomBarStyle">
        <EuiOverlay :visible="true"/>
        <EuiButton class="eui-u-mr-10"
                   :disabled="currentStep === 1"
                   variant="raised"
                   color="primary"
                   @click="previous">
          {{ $t('button.previous') }}
        </EuiButton>
        <EuiButton variant="raised"
                   color="primary"
                   @click="next"
                   :disabled="(currentStep === 4 && (!submitEnable || signbook.signsList.length === 0)) || (currentStep === 2 && signbook.signatories.length === 0) || !valid">
          <template v-if="currentStep === 4">
            {{ $t('button.send') }}
          </template>
          <template v-else-if="currentStep < 4">
            {{ $t('button.next') }}
          </template>
        </EuiButton>
      </EuiBottomBar>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ConfirmDialog from '../../../pages/paraphers/dialogs/ConfirmDialog';
import DOMpurify from 'dompurify';

function getEndingDate() {
  let date = new Date();
  date = new Date(date.setDate(date.getDate() + 15));
  return date.toISOString().split('T')[0];
}
function initialSignbook() {
  return {
    name: '',
    endingDate: getEndingDate(),
    notificationEmail: '',
    allowDecline: true,
    allowFirstThenSimultaneousSteps: false,
    allowSimultaneousSteps: false,
    alertConfiguration: { frequency: 2, intervall: 2 },
    signatureOrdering: 'sequentially',
    signatories: [],
    documents: [],
    steps: [],
    signsList: [],
    recipientOrganizations: [],
    thirdPartiesEmails: [],
    shouldUpdatePosition: false,
  };
}

export default {
  name: 'CreateParapher',
  provide() {
    return {
      Signbook: this.signbook,
      Validation: {
        validate: this.validate,
        validateSignatories: this.validateSignatories,
      },
    };
  },
  props: {
    templateId: {
      type: [String, Number],
      required: false,
    },
  },
  components: {
    ConfirmDialog,
  },
  computed: {
    ...mapState({
      isSidenavOpen: (state) => state.ModuleEdocSign.ui.isSidenavOpen,
      isMiniVariant: (state) => state.ModuleEdocSign.ui.isMiniVariant,
      isMobileView: (state) => state.application.responsive.xs,
      isDesktopView: (state) => state.application.responsive.lg,
      routeName: (state) => state.route.name,
      currentStep: (state) => state.route.meta.step,
    }),
    ...mapGetters({
      user: 'ModuleEdocSign/auth/user',
      can: 'ModuleEdocSign/auth/can',
      options: 'ModuleEdocSign/auth/options',
    }),
    isTemplate() {
      return this.routeName.startsWith('templates.use');
    },
    bottomBarStyle() {
      return {
        '-mini': !this.isMobileView && this.isSidenavOpen && this.isMiniVariant,
        '-default': this.isSidenavOpen && !this.isMiniVariant,
      };
    },
    hasExternalSignatory() {
      return this.signbook.signatories.some(s => s.type === 'externalSignatory');
    },
  },
  watch: {
    templateId(value) {
      this.useTemplateById(value);
    },
    options(value) {
      if (value) {
        this.checkRights();
      }
    },
    $route(to, from) {
      if (this.previousStep === undefined &&
        this.currentStep !== 1 &&
        to.name !== 'paraphers.create.success') {
        this.goToStep(1);
      }
    },
  },
  data() {
    return {
      templateName: undefined,
      steps: [],
      submitEnable: true,
      noZoneDocuments: [],
      noZoneSignatories: [],
      allSignatoriesAreExternal: false,
      externalUserHasZone: false,
      isConfirmDialogOpened: false,
      previousStep: undefined,
      signbook: initialSignbook(),
      isEnlarge: false,
    };
  },
  methods: {
    ...mapActions({
      findById: 'ModuleEdocSign/templates/findById',
      createSignworkflow: 'ModuleEdocSign/parapheurs/createSignworkflow',
      getUser: 'ModuleEdocSign/auth/getUserInfos',
      getUsageDatas: 'ModuleEdocSign/auth/getUsageDatas',
    }),
    initSteps(startsWith) {
      return [{
        routeName: `${startsWith}.addParameters`,
        isValid: undefined,
      },
      {
        routeName: `${startsWith}.addSignatories`,
        isValid: undefined,
      },
      {
        routeName: `${startsWith}.addDocuments`,
        isValid: undefined,
      },
      {
        routeName: `${startsWith}.addSignatures`,
        isValid: undefined,
      },
      {
        routeName: `${startsWith}.success`,
        isValid: undefined,
      }];
    },
    async validate() {
      this.steps[this.currentStep - 1].isValid = await this.$refs.observer.validate();
    },
    validateSignatories() {
      this.steps[1].isValid = this.signbook.signatories.length > 0 && !this.signbook.signatories.some((item) => item.userId === undefined);
      return this.steps[1].isValid;
    },
    onConfirm() {
      this.isConfirmDialogOpened = false;
      this.signbook.signatories = this.signbook.signatories.filter(target => !this.noZoneSignatories.some(item => item.id === target.id));
      this.onSubmit();
    },
    onSubmit() {
      this.submitEnable = false;
      this.createSignworkflow(this.signbook)
        .then(() => {
          this.getUsageDatas();
          Object.assign(this.$data.signbook, initialSignbook());
          this.previousStep = undefined;
          this.$router.push({ name: this.steps[4].routeName });
        }).finally(() => {
          this.submitEnable = true;
        });
    },
    checkZones() {
      const isUserExternal = (signatory) => signatory.type === 'externalSignatory';
      const docIds = [];
      this.externalUserHasZone = false;
      this.allSignatoriesAreExternal = !this.signbook.signatories.some(signatory => !isUserExternal(signatory));
      for (let i = 0; i < this.signbook.signatories.length; i++) {
        const signatory = this.signbook.signatories[i];
        if (signatory.signatures) {
          for (let j = 0; j < signatory.signatures.length; j++) {
            docIds.push(signatory.signatures[j].zone.position.docId);
            if (!this.externalUserHasZone && isUserExternal(signatory)) {
              this.externalUserHasZone = true;
            }
          }
        }
        if (signatory.paraphs) {
          for (let j = 0; j < signatory.paraphs.length; j++) {
            docIds.push(signatory.paraphs[j].zone.position.docId);
            if (!this.externalUserHasZone && isUserExternal(signatory)) {
              this.externalUserHasZone = true;
            }
          }
        }
        if (signatory.texts) {
          for (let j = 0; j < signatory.texts.length; j++) {
            docIds.push(signatory.texts[j].zone.position.docId);
            if (!this.externalUserHasZone && isUserExternal(signatory)) {
              this.externalUserHasZone = true;
            }
          }
        }
      }
      this.noZoneDocuments = this.signbook.documents.filter(doc => docIds.indexOf(doc.id) === -1);
      this.noZoneSignatories = this.signbook.signatories
        .filter((item) => !item.signatures || (item.signatures && item.signatures.length === 0))
        .filter((item) => !item.paraphs || (item.paraphs && item.paraphs.length === 0))
        .filter((item) => !item.texts || (item.texts && item.texts.length === 0));
      if (this.noZoneDocuments.length > 0 || this.noZoneSignatories.length > 0) {
        this.isConfirmDialogOpened = true;
        return false;
      }
      return true;
    },
    checkRights() {
      if (this.options && this.signbook) {
        this.signbook.allowDecline = this.can('parapherCreationFormDefaultIsAllowDecline', true);
        this.signbook.allowFirstThenSimultaneousSteps = this.can('parapherCreationFormDefaultIsSimultaneous');
        if (this.signbook.allowFirstThenSimultaneousSteps) {
          this.signbook.signatureOrdering = 'simultaneously';
        }
        if (this.signbook.alertConfiguration && this.options.parapherCreationDefaultAlertConfIntervall) {
          this.signbook.alertConfiguration.intervall = parseInt(this.options.parapherCreationDefaultAlertConfIntervall.value);
        }
        if (this.signbook.alertConfiguration && this.options.parapherCreationDefaultAlertConfFrequency) {
          this.signbook.alertConfiguration.frequency = parseInt(this.options.parapherCreationDefaultAlertConfFrequency.value);
        }
        this.canCreateSignatory = this.can('parapherCreationFormSignerCreationAvailable', true);
        this.canSelectArchivalOrgs = this.can('selectCustomerOrgsArchival', true);
        this.canNotifyThirdParties = this.can('notifyThirdParties', true);
      }
    },
    resetForm() {
      Object.assign(this.signbook, initialSignbook());
      requestAnimationFrame(() => {
        this.$refs.observer.reset();
      });
    },
    goToStep(step) {
      if (this.currentStep !== 5 && this.currentStep > step) {
        this.previousStep = this.currentStep;
        this.$router.push({ name: this.steps[step - 1].routeName });
      }
    },
    previous() {
      this.isEnlarge = false;
      if (this.currentStep > 1) {
        this.previousStep = this.currentStep;
        this.$router.push({ name: this.steps[this.currentStep - 2].routeName });
      }
    },
    async next() {
      if (this.currentStep < this.steps.length - 1) {
        this.$refs.observer.validate().then((isValid) => {
          if (this.currentStep === 1) {
            // update ending date for signatories if modified in step 1
            if (this.signbook.signatories.length && this.signbook.endingDate !== this.signbook.signatories[0].date) {
              for (let signatory of this.signbook.signatories) {
                signatory.date = this.signbook.endingDate;
              }
            }
            // sanitize global message
            if (this.signbook.globalMessage !== '') {
              this.signbook.globalMessage = DOMpurify.sanitize(this.signbook.globalMessage);
            }
          }
          this.previousStep = this.currentStep;
          this.steps[this.currentStep - 1].isValid = isValid;
          this.steps[this.currentStep].isValid = undefined;
          if (isValid) {
            this.$router.push({ name: this.steps[this.currentStep].routeName });
          }
        });
      } else {
        if (this.checkZones()) {
          this.onSubmit();
        }
      }
    },
    async useTemplateById(id) {
      return this.findById(id);
    },
  },
  async created() {
    if (this.isTemplate) {
      this.steps = this.initSteps('templates.use');
      this.checkRights();
      const template = await this.useTemplateById(this.templateId);
      Object.assign(this.signbook, template);
      this.templateName = this.signbook.name;
      this.signbook.name = '';
    } else {
      this.steps = this.initSteps('paraphers.create');
      this.checkRights();
    }
    if (this.previousStep === undefined && this.currentStep !== 1) {
      this.$router.push({ name: this.steps[0].routeName });
    }
  },
};
</script>

<style scoped>
.stepper {
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  height: 120px;
}

.stepper.-enlarge {
  height: 0px;
}

.title {
  transition: all 0.2s ease-in-out;
  overflow: hidden;
}

.title.-enlarge {
  font-size: 0px !important;
  line-height: 0px !important;
}
</style>
